@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

#root-content {
    position: absolute;
    top: 0;
}

.pointer {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(148 163 184);
    filter: blur(10px);
    /* Adjust the blur amount as needed */
    pointer-events: none;
    transform: translate(-50%, -50%);
}

body {
    height: 100vh;
}

.image-container::before {
    content: "";
    /* Create an empty pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(2, 98, 188);
    /* Set the desired color */
    opacity: 1;
    /* Adjust the opacity as needed */
    pointer-events: none;
    /* Allows interactions with the underlying image */
}

@media (min-width: 1024px) {

    .hor-bar::after {
        /* Create an empty pseudo-element */
        content: "";
        position: absolute;
        top: 17px;
        width: auto;
        height: 1px;
        width: 15rem;
        display: block;
        margin-left: 14rem;
        background-color: white;
        opacity: 0.5;
    }
}

nav a::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: rgb(14 165 233 / var(--tw-text-opacity));
    bottom: -2px;
    left: 0;
    transform: scaleX(0);
    /* Start with no width */
    transform-origin: center;
    /* Start the animation from the center */
    transition: transform 0.3s ease-in-out;
}

nav a:hover::before {
    transform: scaleX(0.8);
    /* Expand the underline to full width on hover */
}


/* Theme toggler */

label::after {
    content: "";
    position: absolute;
    background: #0ea5e9;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    top: 0;
    left: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

input:checked+label {
    background-color: #6c6868;
}

label {
    transition: 0.3s;
}


input:checked+label::after {
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    background-color: rgb(233, 231, 231);
}